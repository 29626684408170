<template>
  <div>
    <DataTable :value="data" responsiveLayout="scroll" :scrollable="true" class="p-datatable-sm" selectionMode="single"
      :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" dataKey="ID" resizableColumns
      columnResizeMode="fit" ref="dataTable" showGridlines stripedRows sortMode="multiple" removableSort>
      <!--columnResizeMode="expand" -->
      <template #empty> Veri Bulunamadı. </template>
      <template #loading> Yükleniyor. Lüften Bekleyin. </template>
      <Column sortable v-for="col of columns" :field="col.field" :header="col.header" :key="col.RowId">
      </Column>
      <Column v-if="editbutton" :exportable="false" style="max-width: 4rem">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class=" p-button-success" @click="edit(slotProps)" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>

export default {
  props: {
    columns: Array,
    data: Array,
    editbutton : Boolean
  },
  data() {
    return {};
  },
  methods: {
    edit(EditRowData) {
      this.$emit("set-child-data", EditRowData);
    },
  },
  mounted() {
    window.$('.p-datatable-thead').css("z-index", "0");
  }
};
</script>