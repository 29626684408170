<template>
    <div class="">
      <Card class="p-m-2">
        <template #title> Tablo Boyutları </template>
        <template #content>
          <BasicDataTable :columns="columns" :data="TabloBoyutList" :editbutton="false" />
        </template>
      </Card>
    </div>
  </template>
  
  <script>
  import BasicDataTable from "../../DataTable/BasicDataTable.vue";
  import GlobalServis from "@/services/GlobalServis";
  
  export default {
    data() {
      return {
        TabloBoyutList : [],
        columns: [
          { field: "TabloAd", header: "Tablo Adı" },
          { field: "RowSayi", header: "Satır Sayısı" },
          { field: "Alan(MB)",header: "Alan(MB)" },
        ],
      };
    },
    methods: {
      InitServis() {
        GlobalServis.GlobalServis("GET", "GetTabloBoyut", "").then((res) => {
          if (res.status == 200) {
            this.TabloBoyutList = res.data
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        });
      },
    },
    mounted() {
      this.InitServis();
    },
    components: {
        BasicDataTable,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .top-button-area {
    margin-bottom: 5px;
  }
  
  .getirbtn {
    margin-top: 20px;
  }
  </style>
  